@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.values {
  background-color: var(--color-yellow-50);

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(49) 24px;
  }

  &__item {
    display: flex;
    flex-direction: column;

    &::before {
      content: "";
      position: relative;
      align-self: flex-start;
      margin-bottom: 24px;
      width: 48px;
      height: 48px;
      background-image: url("../images/icons/heart.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &:first-of-type {
      width: 100%;
      max-width: 286px;

      &::before {
        content: none;
      }

      p {
        font-size: clamp(16px, 1.0833rem + 0.1852vi, 20px);
      }
    }

    p {
      font-size: 16px;
    }

    &--integrity {
      &::before {
        background-image: url("../images/icons/integry.svg");
      }
    }

    &--quality {
      &::before {
        background-image: url("../images/icons/simple-quality.svg");
      }
    }

    &--reliability {
      &::before {
        background-image: url("../images/icons/reliability.svg");
      }
    }

    &--innovation {
      &::before {
        background-image: url("../images/icons/innovation.svg");
      }
    }

    &--customer {
      &::before {
        background-image: url("../images/icons/heart.svg");
      }
    }
  }

  h2,
  h3 {
    margin-bottom: 24px;
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }
  }

  @include media(mobile-m) {
    &__item {
      &:first-of-type {
        max-width: 100%;
      }
    }
  }
}
