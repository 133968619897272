@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";
:root {
  --content-width: 1215px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Inter", sans-serif;
  --font-family-secondary: "Roboto", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --color-dark-900: #292e3d;
  --color-yellow-50: #fff9ed;
  --color-yellow-900: #936400;
  --color-yellow-400: #e1a932;
  --color-white: #fff;
  --color-black: #312a27;
  --color-gray-50: #e0e0e0;
  --radius-main: 8px;
  --gradient-white: linear-gradient(90deg, #f9f2e7 25.667%, #e7f3f900 100%);
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  --radius-big: 32px;
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-black);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  scroll-padding: var(--header-height);
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .page {
    --radius-big: 16px;
  }
}

.section, .background {
  padding: 5.375rem 0;
}

@media only screen and (max-width: 992px) {
  .section, .background {
    padding: 2rem 0;
  }
}

.background {
  background-color: var(--color-yellow-50);
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

.logo {
  width: 100%;
  max-width: 325px;
  margin-right: 10px;
}

.logo__img {
  width: 100%;
}

@media only screen and (max-width: 1180px) {
  .logo {
    max-width: 300px;
  }
}

.hidden {
  opacity: 0;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible:focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  font-weight: var(--fw-600);
  font-size: clamp(28px, 1.1667rem + 2.5926vi, 56px);
  line-height: 1.21;
  overflow: hidden;
}

h1 {
  margin-bottom: 24px;
}

h2, .title {
  font-size: clamp(24px, 1rem + 2.2222vi, 48px);
  line-height: 1.2;
}

.title {
  text-align: center;
  margin: 0 auto 3rem;
}

h3 {
  font-size: clamp(18px, 1.0833rem + .1852vi, 20px);
  line-height: 1.2;
}

p {
  opacity: .86;
  font-size: clamp(16px, 1.0833rem + .1852vi, 20px);
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-white);
  padding: 20px 0;
  transition-property: transform, box-shadow;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-200%);
}

.header.scroll {
  box-shadow: 0 0 10px 0 var(--color-yellow-400);
}

@media only screen and (max-width: 992px) {
  .header {
    padding: 10px 0;
  }

  .header .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.5);
    width: 100%;
    height: calc(var(--vh));
    background-color: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .header .nav__list {
    z-index: -1;
    flex-direction: column;
    margin-right: 0;
  }

  .header .nav__item:not(:last-child) {
    margin-right: 0;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.nav__list {
  align-items: center;
  margin-right: 24px;
  display: flex;
}

@media only screen and (max-width: 1280px) {
  .nav__list {
    margin-right: 10px;
  }
}

.nav__item:not(:last-of-type) {
  margin-right: 16px;
}

@media only screen and (max-width: 1180px) {
  .nav__item:not(:last-of-type) {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  font-size: 16px;
  line-height: 1.8;
  transition: color .3s linear;
}

.nav__link:hover {
  color: var(--color-yellow-400);
}

.nav__link.true {
  color: var(--color-yellow-900);
}

.nav__btns {
  gap: 8px;
  display: flex;
}

.nav--footer {
  width: 100%;
  max-width: 1247px;
  margin: 0 auto;
  padding: 0 16px;
}

@media only screen and (max-width: 992px) {
  .nav--footer, .nav--footer .nav__inner {
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }

  .nav--footer .nav__list {
    margin: 0;
  }
}

@media only screen and (max-width: 475px) {
  .nav--footer .nav__list {
    flex-direction: column;
  }

  .nav--footer .nav__item {
    margin: 0 0 16px;
  }
}

.nav .nav__link {
  background-color: #0000;
}

[class].btn, button.formsapp-popup-button {
  border-radius: calc(var(--radius-big) * 1.56);
  width: 100%;
  height: max-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: var(--fw-500);
  color: var(--color-white);
  background-color: var(--color-yellow-400);
  border: 1px solid #0000;
  justify-content: center;
  align-items: center;
  padding: 18px 23px;
  font-size: 18px;
  line-height: 1;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  display: flex;
}

[class].btn:hover, [class].btn:focus, button.formsapp-popup-button:hover, button.formsapp-popup-button:focus {
  border-color: var(--color-yellow-400);
  color: var(--color-yellow-400);
  background-color: var(--color-white);
}

[class].btn.formsapp-button, [class].btn button.formsapp-popup-button, button.formsapp-popup-button.formsapp-button, button.formsapp-popup-button button.formsapp-popup-button {
  font-family: var(--font-family-primary);
  margin: 0;
  background-color: var(--color-yellow-900) !important;
}

[class].btn.formsapp-button:hover, [class].btn.formsapp-button:focus, [class].btn button.formsapp-popup-button:hover, [class].btn button.formsapp-popup-button:focus, button.formsapp-popup-button.formsapp-button:hover, button.formsapp-popup-button.formsapp-button:focus, button.formsapp-popup-button button.formsapp-popup-button:hover, button.formsapp-popup-button button.formsapp-popup-button:focus {
  border-color: var(--color-yellow-400) !important;
  color: var(--color-yellow-400) !important;
  background-color: var(--color-pink-100) !important;
}

[class].btn--sing, [class].btn--request, button.formsapp-popup-button--sing, button.formsapp-popup-button--request {
  border-color: var(--color-yellow-900);
  border-radius: calc(var(--radius-main) * 4);
  color: var(--color-yellow-900);
  background-color: var(--color-white);
  padding: 10px 16px;
}

[class].btn--request, [class].btn button.formsapp-popup-button, button.formsapp-popup-button--request, button.formsapp-popup-button button.formsapp-popup-button {
  color: var(--color-white) !important;
  background-color: var(--color-yellow-900) !important;
  border-color: #0000 !important;
}

[class].btn--big, button.formsapp-popup-button--big {
  max-width: 173px;
  margin: 0 auto;
}

.burger {
  --line-height: 2px;
  --burger-size: 20px;
  --move: calc(var(--burger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-yellow-900);
  width: calc(var(--burger-size)  + 5px);
  height: var(--burger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy {
  letter-spacing: .02em;
}

.policy__content:not(:last-child) {
  margin-bottom: 3rem;
}

.policy h1 {
  letter-spacing: 0;
}

.policy h2 {
  color: var(--color-yellow-900);
  margin-bottom: 24px;
  font-size: clamp(18px, .875rem + 1.1111vi, 30px);
  line-height: 1.33;
}

.policy ul {
  margin-bottom: 20px;
  padding-left: 15px;
}

.policy li {
  letter-spacing: .02em;
  padding-left: 10px;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
}

.policy p {
  opacity: 1;
  line-height: 1.2;
}

.policy p:not(:last-child) {
  margin-bottom: 20px;
}

.policy a {
  color: var(--color-yellow-900);
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .policy h1, .policy__content:not(:last-child) {
    margin-bottom: 24px;
  }
}

.footer__top {
  background-color: var(--color-yellow-50);
  padding: 3.5rem 0;
}

.footer__bottom {
  justify-content: space-between;
  align-items: center;
  padding: 22px 0;
  display: flex;
}

.footer__copyright {
  opacity: 1;
  font-size: 16px;
  line-height: 1.18;
}

.footer__list {
  justify-content: space-between;
  gap: 16px;
  display: flex;
}

.footer__item:not(:last-child) {
  margin-right: 1.9375rem;
}

.footer__link {
  transition: color .3s linear;
}

.footer__link:hover {
  color: var(--color-blue-800);
}

@media only screen and (max-width: 768px) {
  .footer__top {
    padding: 30px 0;
  }

  .footer__bottom {
    text-align: center;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .footer__item:not(:last-child) {
    margin-right: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .footer__item:not(:last-child) {
    margin: 0;
  }
}

.cards {
  border: 1px solid var(--color-gray-50);
  border-radius: calc(var(--radius-main) * 4);
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.cards__content {
  height: 100%;
  flex-direction: column;
  padding: 24px;
  display: flex;
}

.cards img {
  width: 100%;
}

.cards h3 {
  letter-spacing: .02em;
  margin-bottom: 7px;
  font-size: clamp(18px, 1rem + .5556vi, 24px);
  line-height: 1.5;
}

.cards ul {
  margin-bottom: 26px;
  padding-left: 20px;
}

.cards ul li {
  font-size: 14px;
  line-height: 1.6;
  list-style-type: disc;
}

.cards ul li::marker {
  width: 5px;
  height: 5px;
  color: var(--color-black);
}

.cards a {
  width: -moz-fit-content;
  width: fit-content;
  color: var(--color-yellow-900);
  align-items: center;
  gap: 10px;
  margin-top: auto;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 1;
  display: flex;
}

.cards a:after {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url("../images/icons/arrow.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform .4s linear;
  position: relative;
}

.cards a:hover:after {
  transform: translateX(4px);
}

.cards p {
  font-size: 14px;
  line-height: 1.6;
}

.contact {
  position: relative;
}

.contact:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--color-yellow-900);
  position: absolute;
  top: 0;
}

.contact__inner {
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: 24px;
  display: grid;
}

.contact__form {
  width: 100%;
  max-width: 596px;
  margin-inline: auto;
}

.contact__form label {
  text-align: left;
  display: block;
}

.contact__form label:not(:last-of-type) {
  margin-bottom: 18px;
}

.contact__form label:last-of-type {
  margin-bottom: 24px;
}

.contact__form textarea, .contact__form input {
  width: 100%;
  height: 48px;
  background-color: var(--color-yellow-50);
  border-color: #0000;
  border-radius: 28px;
  padding: 16px;
}

.contact__form textarea::placeholder, .contact__form input::placeholder {
  opacity: .64;
  font-size: 16px;
  line-height: 1;
}

.contact__form textarea {
  height: 156px;
  resize: none;
}

.contact__info {
  flex-direction: column;
  justify-self: center;
  display: flex;
  translate: -12px 31px;
}

.contact__info h3 {
  margin-bottom: 28px;
  font-size: 20px;
}

.contact__info a {
  font-family: var(--font-family-secondary);
  color: var(--color-yellow-900);
  margin-bottom: 28px;
  font-size: 16px;
  line-height: 1.4;
  text-decoration: underline;
  transition: -webkit-text-decoration-color .3s linear, text-decoration-color .3s linear;
}

.contact__info a:hover {
  -webkit-text-decoration-color: #0000;
  text-decoration-color: #0000;
}

.contact__info ul {
  font-family: var(--font-family-secondary);
  font-size: 16px;
  line-height: 1.4;
}

.contact h2 {
  margin-bottom: 32px;
}

.contact .btn {
  max-width: 100%;
}

@media only screen and (max-width: 576px) {
  .contact__inner {
    grid-template-columns: 1fr;
    justify-content: center;
    padding-bottom: 40px;
  }

  .contact__info {
    transform: none;
  }
}

.hero {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero:before {
  content: "";
  z-index: 1;
  background: var(--gradient-white);
  position: absolute;
  inset: 0;
}

.hero__inner {
  z-index: 1;
  min-height: 646px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
}

.hero__content {
  width: 100%;
  max-width: 574px;
  translate: 0 13px;
}

.hero h1 {
  margin-bottom: 24px;
}

.hero p {
  margin-bottom: 34px;
}

.hero .btn, .hero [class] button.formsapp-popup-button {
  color: var(--color-black) !important;
  background-color: var(--color-white) !important;
}

@media only screen and (max-width: 992px) {
  .hero:before {
    width: 100%;
    background-color: var(--color-white);
    opacity: .8;
  }

  .hero__inner {
    min-height: 380px;
    padding: 16px 0;
  }

  .hero__content {
    max-width: 100%;
    translate: 0;
  }
}

.accessory__inner {
  justify-content: space-between;
  gap: 24px;
  display: flex;
}

.accessory__inner--reverse {
  align-items: center;
  margin-bottom: 4.6875rem;
}

.accessory__content {
  width: 100%;
  max-width: 493px;
}

.accessory__image {
  border-radius: calc(var(--radius-main) * 4);
  width: 100%;
  max-width: 596px;
  overflow: hidden;
}

.accessory h2, .accessory h3 {
  margin-bottom: 32px;
}

.accessory h3 {
  font-size: clamp(20px, .8333rem + 1.8519vi, 40px);
}

.accessory--projects h2 {
  text-align: center;
  margin-bottom: 5.375rem;
}

@media only screen and (max-width: 992px) {
  .accessory--projects h2 {
    margin-bottom: 30px;
  }
}

.accessory--projects .accessory__inner {
  margin-bottom: 8.375rem;
}

@media only screen and (max-width: 992px) {
  .accessory--projects .accessory__inner {
    margin-bottom: 30px;
  }
}

.accessory--projects .accessory__inner--reverse {
  align-items: center;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 992px) {
  .accessory--projects .accessory__inner--reverse {
    margin-bottom: 20px;
  }
}

.accessory--home .accessory__inner {
  align-items: center;
  padding-top: 4rem;
  position: relative;
}

.accessory--home .accessory__inner:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--color-yellow-50);
  position: absolute;
  top: 0;
}

@media only screen and (max-width: 992px) {
  .accessory--home .accessory__inner {
    padding-top: 2rem;
  }
}

.accessory--home .accessory__image img {
  height: 100%;
}

.accessory--home p:first-of-type {
  margin-bottom: 32px;
}

.accessory.background .accessory__inner:first-of-type, .accessory__mobile .accessory__inner:first-of-type {
  align-items: center;
}

@media only screen and (max-width: 992px) {
  .accessory__inner {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .accessory__inner--reverse {
    flex-direction: column;
    margin-bottom: 35px;
  }

  .accessory__inner__content {
    max-width: 100%;
  }

  .accessory__inner img {
    aspect-ratio: 16 / 9;
  }

  .accessory br {
    display: none;
  }
}

.service__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 3rem;
  display: grid;
}

@media only screen and (max-width: 992px) {
  .service__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 576px) {
  .service__list {
    grid-template-columns: 1fr;
  }
}

.service .title {
  margin-bottom: 32px;
}

.service .title + p {
  text-align: center;
  margin-bottom: 5.4375rem;
}

@media only screen and (max-width: 992px) {
  .service .title + p {
    margin-bottom: 40px;
  }
}

.service--main {
  margin-bottom: 5.375rem;
}

.service--main .service__list {
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  .service--main {
    margin-bottom: 0;
  }
}

.service__garden, .service__mobile {
  margin-bottom: 4rem;
}

@media only screen and (max-width: 992px) {
  .service__garden, .service__mobile {
    margin-bottom: 0;
  }
}

.service--home .title {
  margin-bottom: 3.125rem;
}

.service__mobile .title + p {
  margin-bottom: 5.25rem;
}

@media only screen and (max-width: 992px) {
  .service__mobile .title + p {
    margin-bottom: 40px;
  }

  .service li:last-child {
    max-width: 450px;
    grid-column: 2 span;
    margin: 0 auto;
  }

  .service li:last-child img {
    aspect-ratio: 16 / 9;
  }
}

@media only screen and (max-width: 576px) {
  .service li:last-child {
    max-width: 100%;
    grid-column: auto;
    margin: 0;
  }

  .service li img {
    aspect-ratio: 16 / 9;
  }
}

.modular__inner {
  justify-content: space-between;
  padding: 4rem 0;
  display: flex;
  position: relative;
}

.modular__inner:before, .modular__inner:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--color-yellow-50);
  position: absolute;
  top: 0;
}

.modular__inner:before {
  top: auto;
  bottom: 0;
}

.modular__content {
  width: 100%;
  max-width: 493px;
}

.modular__image {
  border-radius: calc(var(--radius-main) * 4);
  width: 100%;
  max-width: 596px;
  overflow: hidden;
}

.modular__image img {
  height: 100%;
}

.modular h2 {
  margin-bottom: 33px;
}

.modular p:first-of-type {
  margin-bottom: 32px;
}

@media only screen and (max-width: 992px) {
  .modular__inner {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding: 32px 0;
  }

  .modular__content {
    max-width: 100%;
  }

  .modular__image img {
    aspect-ratio: 16 / 9;
  }
}

.values {
  background-color: var(--color-yellow-50);
}

.values__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 3.0625rem 24px;
  display: grid;
}

.values__item {
  flex-direction: column;
  display: flex;
}

.values__item:before {
  content: "";
  width: 48px;
  height: 48px;
  background-image: url("../images/icons/heart.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  align-self: flex-start;
  margin-bottom: 24px;
  position: relative;
}

.values__item:first-of-type {
  width: 100%;
  max-width: 286px;
}

.values__item:first-of-type:before {
  content: none;
}

.values__item:first-of-type p {
  font-size: clamp(16px, 1.0833rem + .1852vi, 20px);
}

.values__item p {
  font-size: 16px;
}

.values__item--integrity:before {
  background-image: url("../images/icons/integry.svg");
}

.values__item--quality:before {
  background-image: url("../images/icons/simple-quality.svg");
}

.values__item--reliability:before {
  background-image: url("../images/icons/reliability.svg");
}

.values__item--innovation:before {
  background-image: url("../images/icons/innovation.svg");
}

.values__item--customer:before {
  background-image: url("../images/icons/heart.svg");
}

.values h2, .values h3 {
  margin-bottom: 24px;
}

@media only screen and (max-width: 768px) {
  .values__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}

@media only screen and (max-width: 576px) {
  .values__list {
    grid-template-columns: 1fr;
  }

  .values__item:first-of-type {
    max-width: 100%;
  }
}

.project__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 3rem;
  display: grid;
}

.project__item {
  z-index: 1;
  border-radius: calc(var(--radius-main) * 4);
  position: relative;
  overflow: hidden;
}

.project__item:before {
  content: "";
  z-index: 1;
  background: linear-gradient(#0000, #000 125.647%);
  border-radius: 8px;
  position: absolute;
  inset: 0;
}

.project__item h3 {
  z-index: 1;
  letter-spacing: .01em;
  color: var(--color-white);
  margin: 0 auto;
  padding: 0 24px;
  font-size: clamp(18px, 1rem + .5556vi, 24px);
  line-height: 1.5;
  position: absolute;
  bottom: 24px;
}

.project .btn {
  margin: 0 auto;
}

.project .title {
  margin-bottom: 3.125rem;
}

@media only screen and (max-width: 992px) {
  .project br {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .project__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .project__item:last-child {
    grid-column: 2 span;
  }

  .project__item:last-child img {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
}

@media only screen and (max-width: 576px) {
  .project__list {
    grid-template-columns: 1fr;
  }

  .project__item:last-child {
    grid-column: auto;
  }

  .project img {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
}

.faq__item:not(:last-child) {
  margin-bottom: 17px;
}

.faq details {
  border-radius: var(--radius-main);
  background-color: var(--color-yellow-50);
  overflow: hidden;
}

.faq details[open] summary {
  padding-bottom: 12px;
}

.faq details[open] summary:after {
  transform: scaleY(1);
}

.faq summary {
  font-weight: var(--fw-600);
  letter-spacing: .02em;
  cursor: pointer;
  padding: 16px 54px 16px 24px;
  font-size: clamp(16px, .9583rem + .1852vi, 18px);
  line-height: 1;
  position: relative;
}

.faq summary:after {
  content: "";
  width: 32px;
  height: 32px;
  background-color: currentColor;
  background-color: var(--color-yellow-400);
  transition: transform .3s linear;
  position: absolute;
  top: 16px;
  right: 24px;
  transform: scaleY(-1);
  -webkit-mask-image: url("../images/icons/chevron-down.svg");
  mask-image: url("../images/icons/chevron-down.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  .faq summary:after {
    top: 10px;
    right: 5px;
  }
}

.faq summary + div {
  padding: 0 24px 16px;
}

.faq summary + div p {
  width: 97%;
  opacity: 1;
  font-size: 16px;
  line-height: 1.6;
}

@media only screen and (max-width: 576px) {
  .faq summary + div p {
    width: 100%;
  }
}

.faq h2 {
  text-align: center;
  margin: 0 auto 3rem;
}

.benefits__list {
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 24px;
  display: grid;
}

.benefits__item {
  border: 1px solid var(--color-gray-50);
  border-radius: calc(var(--radius-main) * 4);
  height: 100%;
  flex-direction: column;
  padding: 1.875rem;
  display: flex;
  overflow: hidden;
}

.benefits__item:before {
  content: "";
  width: 48px;
  height: 48px;
  background-image: url("../images/icons/bubble.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  align-self: flex-start;
  margin-bottom: 24px;
  position: relative;
}

.benefits__item p {
  font-size: 16px;
}

.benefits__item--response:before {
  background-image: url("../images/icons/bubble.svg");
}

.benefits__item--quality:before {
  background-image: url("../images/icons/quality.svg");
}

.benefits__item--pricing:before {
  background-image: url("../images/icons/price.svg");
}

.benefits__item--customer:before {
  background-image: url("../images/icons/people.svg");
}

.benefits h2 {
  margin-bottom: 4rem;
}

.benefits h3 {
  margin-bottom: 24px;
}

@media only screen and (max-width: 992px) {
  .benefits__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .benefits h2 {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 576px) {
  .benefits__list {
    grid-template-columns: 1fr;
  }

  .benefits__item {
    padding: 16px;
  }
}

/*# sourceMappingURL=main.css.map */
