@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.project {
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: rem(48);
    gap: 24px;
  }

  &__item {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 4);

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      border-radius: 8px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgb(0, 0, 0) 125.647%);
      inset: 0;
    }

    h3 {
      position: absolute;
      bottom: 24px;
      z-index: 1;
      margin: 0 auto;
      padding: 0 24px;
      font-size: clamp(18px, 1rem + 0.5556vi, 24px);
      line-height: 1.5;
      letter-spacing: 0.01em;
      color: var(--color-white);
    }
  }

  .btn {
    margin: 0 auto;
  }

  .title {
    margin-bottom: rem(50);
  }

  @include media(tablet) {
    br {
      display: none;
    }
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      &:last-child {
        grid-column: 2 span;

        img {
          width: 100%;
          aspect-ratio: 16/9;
        }
      }
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }
    }

    img {
      width: 100%;
      aspect-ratio: 16/9;
    }
  }
}
