@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.footer {
  &__top {
    padding: rem(56) 0;
    background-color: var(--color-yellow-50);
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 0;
  }

  &__copyright {
    font-size: 16px;
    line-height: 1.18;
    opacity: 1;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }

  &__item {
    &:not(:last-child) {
      margin-right: rem(31);
    }
  }

  &__link {
    transition: color 0.3s linear;

    &:hover {
      color: var(--color-blue-800);
    }
  }

  @include media(mobile-l) {
    &__top {
      padding: 30px 0;
    }

    &__bottom {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 16px;
    }

    &__item {
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }

  @include media(mobile-m) {
    &__item {
      &:not(:last-child) {
        margin: 0;
      }
    }
  }
}
