@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.accessory {
  $root: &;

  &__inner {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    &--reverse {
      align-items: center;
      margin-bottom: rem(75);
    }
  }

  &__content {
    width: 100%;
    max-width: 493px;
  }

  &__image {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 4);
    width: 100%;
    max-width: 596px;
  }

  h2,
  h3 {
    margin-bottom: 32px;
  }

  h3 {
    font-size: clamp(20px, 0.8333rem + 1.8519vi, 40px);
  }

  &--projects {
    h2 {
      margin-bottom: rem(86);
      text-align: center;

      @include media(tablet) {
        margin-bottom: 30px;
      }
    }

    #{$root}__inner {
      margin-bottom: rem(134);

      @include media(tablet) {
        margin-bottom: 30px;
      }

      &--reverse {
        align-items: center;
        margin-bottom: rem(48);

        @include media(tablet) {
          margin-bottom: 20px;
        }
      }
    }
  }

  &--home {
    #{$root}__inner {
      position: relative;
      padding-top: rem(64);
      align-items: center;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 1px;
        background-color: var(--color-yellow-50);
      }

      @include media(tablet) {
        padding-top: rem(32);
      }
    }

    #{$root}__image {
      img {
        height: 100%;
      }
    }

    p {
      &:first-of-type {
        margin-bottom: 32px;
      }
    }
  }

  &.background,
  &__mobile {
    .accessory__inner:first-of-type {
      align-items: center;
    }
  }

  @include media(tablet) {
    &__inner {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;

      &--reverse {
        flex-direction: column;
        margin-bottom: 35px;
      }

      &__content {
        max-width: 100%;
      }

      img {
        aspect-ratio: 16/9;
      }
    }

    br {
      display: none;
    }
  }
}
