/* stylelint-disable declaration-no-important */
@use "../utils/functions/"as *;
@use "../utils/mixins"as *;

*[class].btn,
button.formsapp-popup-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: calc(var(--radius-big) * 1.56);
  padding: 18px 23px;
  width: 100%;
  height: max-content;
  max-width: fit-content;
  font-weight: var(--fw-500);
  font-size: 18px;
  line-height: 1;
  color: var(--color-white);
  background-color: var(--color-yellow-400);
  transition-property: background-color, color, border-color;
  transition-duration: 0.3s;

  &:hover,
  &:focus {
    border-color: var(--color-yellow-400);
    color: var(--color-yellow-400);
    background-color: var(--color-white);
  }

  &.formsapp-button,
  button.formsapp-popup-button {
    margin: 0;
    font-family: var(--font-family-primary);
    background-color: var(--color-yellow-900) !important;

    &:hover,
    &:focus {
      border-color: var(--color-yellow-400) !important;
      color: var(--color-yellow-400) !important;
      background-color: var(--color-pink-100) !important;
    }
  }

  &--sing,
  &--request {
    border-color: var(--color-yellow-900);
    border-radius: calc(var(--radius-main) * 4);
    padding: 10px 16px;
    color: var(--color-yellow-900);
    background-color: var(--color-white);
  }

  &--request,
  button.formsapp-popup-button {
    border-color: transparent !important;
    color: var(--color-white) !important;
    background-color: var(--color-yellow-900) !important;
  }

  &--big {
    margin: 0 auto;
    max-width: 173px;
  }
}