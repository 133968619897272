/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --content-width: 1215px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Inter", sans-serif;
   --font-family-secondary: "Roboto", sans-serif;

  // font-weight
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;

  // color
  --color-dark-900: #292e3d;
  --color-yellow-50: #fff9ed;
  --color-yellow-900: #936400;
  --color-yellow-400: #e1a932;
  --color-white: #fff;
  --color-black: #312a27;
  --color-gray-50: #e0e0e0;

  //  radius
  --radius-main: 8px;

  --gradient-white: linear-gradient(90deg, rgb(249, 242, 231) 25.667%, rgba(231, 243, 249, 0) 100%);
}
