@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 20px 0;
  width: 100%;
  background-color: var(--color-white);
  transition-property: transform, box-shadow;
  transition-duration: 0.15s;

  &.hide {
    transform: translateY(-200%);
  }

  &.scroll {
    box-shadow: 0 0 10px 0 var(--color-yellow-400);
  }

  @include media(tablet) {
    padding: 10px 0;

    .nav__inner {
      position: fixed;
      left: 0;
      top: 0;
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding-top: calc(var(--header-height) * 1.5);
      width: 100%;
      height: calc(var(--vh));
      background-color: var(--color-white);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.4s;
    }

    .nav__list {
      z-index: -1;
      flex-direction: column;
      margin-right: 0;
    }

    .nav__item {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}
