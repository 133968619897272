@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.modular {
  &__inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: rem(64) 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      background-color: var(--color-yellow-50);
    }

    &::before {
      top: auto;
      bottom: 0;
    }
  }

  &__content {
    width: 100%;
    max-width: 493px;
  }

  &__image {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 4);
    width: 100%;
    max-width: 596px;

    img {
      height: 100%;
    }
  }

  h2 {
    margin-bottom: 33px;
  }

  p {
    &:first-of-type {
      margin-bottom: 32px;
    }
  }

  @include media(tablet) {
    &__inner {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      padding: 32px 0;
      gap: 32px;
    }

    &__content {
      max-width: 100%;
    }

    &__image img {
      aspect-ratio: 16/9;
    }
  }
}
