@use "../utils/functions/"as *;
@use "../utils/mixins/"as *;

.hero {
  $root: &;

  position: relative;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    background: var(--gradient-white);
    inset: 0;
  }

  &__inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 646px;
  }

  &__content {
    width: 100%;
    max-width: 574px;
    translate: 0 13px;
  }

  h1 {
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 34px;
  }

  .btn,
  *[class]button.formsapp-popup-button {
    color: var(--color-black) !important;
    background-color: var(--color-white) !important;
  }

  @include media(tablet) {
    &::before {
      width: 100%;
      background-color: var(--color-white);
      opacity: 0.8;
    }

    &__inner {
      padding: 16px 0;
      min-height: 380px;
    }

    &__content {
      max-width: 100%;
      translate: none;
    }
  }
}