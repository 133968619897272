@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

h1,
h2,
h3 {
  overflow: hidden;
  font-weight: var(--fw-600);
  font-size: clamp(28px, 1.1667rem + 2.5926vi, 56px);
  line-height: 1.21;
}

h1 {
  margin-bottom: 24px;
}

h2,
.title {
  font-size: clamp(24px, 1rem + 2.2222vi, 48px);
  line-height: 1.2;
}

.title {
  margin: 0 auto rem(48);
  text-align: center;
}

h3 {
  font-size: clamp(18px, 1.0833rem + 0.1852vi, 20px);
  line-height: 1.2;
}

p {
  font-size: clamp(16px, 1.0833rem + 0.1852vi, 20px);
  opacity: 0.86;
}



