@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.cards {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid var(--color-gray-50);
  border-radius: calc(var(--radius-main) * 4);
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    padding: 24px;
    height: 100%;
  }

  img {
    width: 100%;
  }

  h3 {
    margin-bottom: 7px;
    font-size: clamp(18px, 1rem + 0.5556vi, 24px);
    line-height: 1.5;
    letter-spacing: 0.02em;
  }

  ul {
    margin-bottom: 26px;
    padding-left: 20px;

    li {
      list-style-type: disc;
      font-size: 14px;
      line-height: 1.6;

      &::marker {
        width: 5px;
        height: 5px;
        color: var(--color-black);
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: 10px;
    width: fit-content;
    font-size: 18px;
    line-height: 1;
    color: var(--color-yellow-900);
    gap: 10px;

    &::after {
      content: "";
      position: relative;
      width: 18px;
      height: 18px;
      background-image: url("../images/icons/arrow.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
      transition: transform 0.4s linear;
    }

    &:hover {
      &::after {
        transform: translateX(4px);
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 1.6;
  }
}
