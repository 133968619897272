@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.nav {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  &__list {
    display: flex;
    align-items: center;
    margin-right: 24px;

    @include media(desktop) {
      margin-right: 10px;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 16px;

      @include media(tablet-wide) {
        margin-right: 10px;
      }
    }

    @include media(tablet) {
      margin-bottom: 30px;
    }
  }

  &__link {
    font-size: 16px;
    line-height: 1.8;
    transition: color 0.3s linear;

    &:hover {
      color: var(--color-yellow-400);
    }

    &.true {
      color: var(--color-yellow-900);
    }
  }

  &__btns {
    display: flex;
    gap: 8px;
  }

  &--footer {
    width: 100%;
    max-width: 1247px;
    margin: 0 auto;
    padding: 0 16px;

    @include media(tablet) {
      flex-direction: column;
      justify-content: center;
      gap: 24px;
    }

    #{$root}__inner {
      @include media(tablet) {
        flex-direction: column;
        justify-content: center;
        gap: 24px;
      }
    }

    #{$root}__list {
      @include media(tablet) {
        margin: 0;
      }

      @include media(mobile-s) {
        flex-direction: column;
      }
    }

    #{$root}__item {

        @include media(mobile-s) {
          margin: 0 0 16px 0;
        }
    }
  }

  #{$root}__link {
    background-color: transparent;
  }
}
